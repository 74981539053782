.Container {
    display: block;
    padding: 0px 550px;
}

.Title {
    padding: 0 10px;
}

.Subtitle {
    padding: 20px 20px 0 20px;
}

.Building {
    display: block;
    margin: 0 10px 40px 10px;
}

.Floor {
    border: 2px solid #ccc;
    margin: 10px;
}

.Rooms {
    display: block;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
    padding: 0 20px;
}

.Blank {
    width: 55px;
    margin: 5;
    padding: 5;
    border: 2px solid #fff;
}

.Number {
    text-align: center;
    margin: 5px;
    margin-bottom: 0;
    padding: 5px;
}

.Capacity {
    text-align: center;
    border-top: 1px solid #555;
    margin: 5px;
    margin-top: 0;
    padding: 5px;
}

.Room {
    width: 55px;
    margin: 5;
    padding: 5;
    cursor: pointer;
    border: 2px solid #ccc;
}

.MobileRoom {
    width: 20px;
    margin: 1;
    padding: 1;
    border: 1px solid #ccc;
}

.Empty {
    background-color: lightgreen;
}

.Half {
    background-color: sandybrown;
}

.Full {
    background-color: #ff4d4f;
}