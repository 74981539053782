body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @media(max-width: 576px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
} */

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none !important;
}

.ant-picker-panel-container, .ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important; 
}

table {
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
}