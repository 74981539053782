
.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Logo {
    margin: 20px 0;
}

.Controls {
    margin: 20px 0;
}

.AuthBtn {
    /*  */
}

.Hidden {
    display: none;
}