.Container {
    /*  */
}

.Back {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px;
}

.Details {
    margin: 20px 40px;
    padding: 10; 
}

.Table {
    margin: 0;
    padding: 0;
    table-layout: fixed;
    border: 1px solid #eee;
}

.Table tbody td {
    padding: 10px;
    word-wrap: break-word;
}

.Table tbody tr:nth-child(even) {
    background-color: #eee;
}

.Controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 20px;
    padding: 20px;
}