.Container {
    /*  */
}

.Back {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.Details {
    margin: 10px 10px;
    padding: 0 
}

.Table {
    margin: 0;
    padding: 0;
    table-layout: fixed;
    border: 1px solid #eee;
}

.Table tbody td {
    padding: 10px;
    word-wrap: break-word;
}

.Table tbody tr:nth-child(even) {
    background-color: #eee;
}

.Controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 5px;
    padding: 5px;
}