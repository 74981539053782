.Container {
    display: block;
    padding: 10px;
    width: 100%;
}

.Title {
    padding: 0;
}

.Subtitle {
    padding: 0 10px;
}

.Building {
    display: block;
    margin: 5px 0;
}

.Floor {
    border: 2px solid #ccc;
    margin: 5px 0;
}

.Rooms {
    display: block;
}

.Room {
    width: 30px;
    margin: 1;
    padding: 1;
    border: 1px solid #ccc;
}

.Blank {
    width: 30px;
    margin: 1;
    padding: 1;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0;
    padding: 0 10px;
}

.Number {
    text-align: center;
    border-bottom: 1px solid #333;
    padding: 3px;
    margin: 3px;
    font-size: 12px;
}

.Capacity {
    text-align: center;
    border-top: 1px solid #333;
    padding: 3px;
    margin: 3px;
    font-size: 12px;
}

.Empty {
    background-color: lightgreen;
}

.Half {
    background-color: sandybrown;
}

.Full {
    background-color: #ff4d4f;
}