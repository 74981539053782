.List {
    padding: 0px;
    color: #353535;
}

.ListItem {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 5px 5px 12px #c9c9c9, -5px -5px 12px #ffffff;
    padding: 20px;
    margin: 10px;
    list-style: none;
    display: flex; 
    justify-content: space-between;
}